import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatChipTabGroupComponent, MatChipTabComponent } from '@shared/components';

@Component({
  selector: 'app-cp-mat-chip-tab',
  imports: [MatChipTabGroupComponent, MatChipTabComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="m-5">
      <app-mat-chip-tab-group (selectedChipChange)="setSelectedChipContent($event)">
        <app-mat-chip-tab label="Tab 1 Name"> First Page Content</app-mat-chip-tab>
        <app-mat-chip-tab label="Tab 2 Name"> Second Page Content</app-mat-chip-tab>
        <app-mat-chip-tab label="Tab 3 Name"> Third Page Content</app-mat-chip-tab>
        <app-mat-chip-tab label="Tab 4 Name"> Forth Page Content</app-mat-chip-tab>
      </app-mat-chip-tab-group>
    </div>
  `,
  styles: ``,
})
export class CpMatChipTabComponent {
  selectedChip: number | undefined;

  setSelectedChipContent(selectedChipIndex: number) {
    this.selectedChip = selectedChipIndex;
  }
}
