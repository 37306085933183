import { KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import {
  KYCStatusEnum,
  MPGTransactionStatusEnum,
  PaymentStatusEnum,
  VerificationRequestStatusEnum,
} from '@core/enums';
import { StatusComponent } from '@shared/components';
import {
  KycStatusPipe,
  MpgTransactionStatusPipe,
  PaymentStatusPipe,
  VerificationRequestStatusPipe,
} from '@shared/pipes';

@Component({
  selector: 'app-cp-status',
  imports: [
    MatTabsModule,
    StatusComponent,
    KeyValuePipe,
    KycStatusPipe,
    VerificationRequestStatusPipe,
    PaymentStatusPipe,
    MpgTransactionStatusPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h1>KYC Status</h1>
    @for (typography of typographyClasses; track $index) {
      <h2>{{ typography }}</h2>
      @for (status of kycStatusValues | keyvalue; track $index) {
        @for (showIcon of [true, false]; track $index) {
          @for (showBorder of [true, false]; track $index) {
            <app-status
              [data]="$any(status.value) | kycStatus"
              [showIcon]="showIcon"
              [showBorder]="showBorder"
              [typography]="typography"
            >
            </app-status>
          }
        }
      }
    }
    <h1>Verification Request Status</h1>
    @for (typography of typographyClasses; track $index) {
      <h2>{{ typography }}</h2>
      @for (status of verificationRequestValues | keyvalue; track $index) {
        @for (showIcon of [true, false]; track $index) {
          @for (showBorder of [true, false]; track $index) {
            <app-status
              [data]="$any(status.value) | verificationRequestStatus"
              [showIcon]="showIcon"
              [showBorder]="showBorder"
              [typography]="typography"
            >
            </app-status>
          }
        }
      }
    }
    <h1>Payment Status</h1>
    @for (typography of typographyClasses; track $index) {
      <h2>{{ typography }}</h2>
      @for (status of paymentValues | keyvalue; track $index) {
        @for (showIcon of [true, false]; track $index) {
          @for (showBorder of [true, false]; track $index) {
            <app-status
              [data]="$any(status.value) | paymentStatus"
              [showIcon]="showIcon"
              [showBorder]="showBorder"
              [typography]="typography"
            >
            </app-status>
          }
        }
      }
    }
    <h1>MPG Transaction Status</h1>
    @for (typography of typographyClasses; track $index) {
      <h2>{{ typography }}</h2>
      @for (status of mpgValues | keyvalue; track $index) {
        @for (showIcon of [true, false]; track $index) {
          @for (showBorder of [true, false]; track $index) {
            <app-status
              [data]="$any(status.value) | mpgTransactionStatus"
              [showIcon]="showIcon"
              [showBorder]="showBorder"
              [typography]="typography"
            >
            </app-status>
          }
        }
      }
    }
  `,
  styles: ``,
})
export class CpStatusComponent {
  kycStatusValues = Object.values(KYCStatusEnum).filter(
    (value) => typeof value === 'number',
  ) as number[];
  verificationRequestValues = Object.values(VerificationRequestStatusEnum).filter(
    (value) => typeof value === 'number',
  ) as number[];
  paymentValues = Object.values(PaymentStatusEnum).filter(
    (value) => typeof value === 'number',
  ) as number[];
  mpgValues = Object.values(MPGTransactionStatusEnum).filter(
    (value) => typeof value === 'number',
  ) as number[];
  typographyClasses: string[] = ['Caption', 'Body-1'];
}
