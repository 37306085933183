import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NoDataComponent } from '@shared/components';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-cp-no-data',
  imports: [NoDataComponent, MatButton],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div class="mb-16">
      Simple No Data:
      <app-no-data> </app-no-data>
    </div>
    <div class="mb-16">
      Two Button No Data:
      <app-no-data>
        <button mat-stroked-button (click)="callButton()">First Button Name</button>
        <button mat-flat-button color="primary" (click)="callButton()">Second Button Name</button>
      </app-no-data>
    </div>
    <div class="mb-16">
      Stroke Style Button No Data:
      <app-no-data>
        <button mat-stroked-button (click)="callButton()">First Button Name</button>
      </app-no-data>
    </div>
    <div>
      Flat Style Button No Data:
      <app-no-data>
        <button mat-flat-button color="primary" (click)="callButton()">Second Button Name</button>
      </app-no-data>
    </div>`,
  styles: ``,
})
export class CpNoDataComponent {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callButton() {}
}
