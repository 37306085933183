import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SidenavComponent, SidePanelComponent } from '@shared/layout';

@Component({
  selector: 'app-cpl',
  imports: [RouterOutlet, SidePanelComponent, SidenavComponent],
  templateUrl: './cpl.component.html',
  styleUrl: './cpl.component.scss',
})
export class CplComponent {}
