// searchable-select.component.ts
import { Component } from '@angular/core';
import { ROUTES } from '@core/constants';
import { LoadStrategy } from '@core/enums';
import { AsyncSelectorOptions } from '@core/types';
import { AsyncSelectorComponent } from '@shared/components';

@Component({
  selector: 'app-searchable-select',
  imports: [AsyncSelectorComponent],
  template: `
    <app-async-selector [dataUrl]="ROUTES.configuration.currency.list" label="Currency">
      <ng-template let-item>
        <span class="app-select-option"
          ><img [src]="item.logoUrl" alt="Currency Logo" />{{ item.name }}</span
        >
      </ng-template>
    </app-async-selector>
    <app-async-selector
      [dataUrl]="ROUTES.users.list"
      [options]="options"
      label="Currency"
      filterable
      searchKey="email"
    >
      <ng-template let-item>
        <span class="app-select-option">{{ item.email }}</span>
      </ng-template>
    </app-async-selector>
    <app-async-selector [dataUrl]="ROUTES.users.list" label="Currency" filterable searchKey="email">
      <ng-template let-item>
        <span class="app-select-option">{{ item.email }}</span>
      </ng-template>
    </app-async-selector>
  `,
  styles: [
    `
      .search-box {
        padding: 8px;
        border-bottom: 1px solid #ddd;
      }
      .w-100 {
        width: 100%;
      }
    `,
  ],
})
export class CpAsyncSelectorComponent {
  ROUTES = ROUTES;
  options: AsyncSelectorOptions = {
    loadStrategy: LoadStrategy.EAGER_ON_INIT,
  };
}
