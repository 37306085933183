<div class="cp-container">
  <div class="sidebar">
    <mat-list role="list">
      @for (component of components; track component) {
        <mat-list-item
          role="listitem"
          [routerLink]="component.route"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ component.label }}
        </mat-list-item>
      }
    </mat-list>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
