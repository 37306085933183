import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import { ThemeService } from '@core/services';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'env';
import { DevToolSheetComponent } from '@dev-tools';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, MatSidenavModule, MatButtonModule, MatIconModule, MatListModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'y-project';
  themeService = inject(ThemeService);
  isProductionMode = environment.production;
  _bottomSheet = inject(MatBottomSheet);
  _router = inject(Router);

  constructor() {
    this.themeService.syncWithUserPreferedTheme();
    this._router.initialNavigation();
  }

  openBottomSheet(): void {
    this._bottomSheet.open(DevToolSheetComponent);
  }
}
