import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CommonValidators } from '@core/validators';
import { MatChipInputComponent } from '@shared/components';
@Component({
  selector: 'app-cp-chips',
  imports: [MatChipInputComponent, JsonPipe, FormsModule, ReactiveFormsModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <section>
      <h4>Chips</h4>
      <form [formGroup]="form" class="chips-input">
        <app-mat-chip-input controlName="bin" label="Card prefix number" />
        <app-mat-chip-input controlName="binsRequired" label="Card prefix number Required" />
        <button mat-button color="primary" [disabled]="form.invalid">Submit</button>
      </form>
      <button mat-button color="primary" (click)="reset()">Reset</button>

      <p>Form value: {{ form.value | json }}</p>
    </section>
  `,
  styles: `
    .chips-input {
      width: 100%;
    }
  `,
})
export class CpChipsComponent {
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    const { required, cardPrefixNumber } = CommonValidators;
    this.form = this.fb.group({
      bin: [[], [cardPrefixNumber()]],
      binsRequired: [[], [required, cardPrefixNumber()]],
    });
  }
  reset() {
    this.form.reset({
      bin: [],
      binsRequired: [],
    });
  }
}
