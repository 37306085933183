<mat-nav-list>
  <button mat-icon-button (click)="themeService.toggleMode()" title="Toggle Theme">
    <mat-icon>
      {{ themeService.currentTheme === ColorModesEnum.LIGHT_MODE ? 'dark_mode' : 'light_mode' }}
    </mat-icon>
  </button>
  <button mat-icon-button routerLink="/cp" title="Component Playground" (click)="closeDevTool()">
    <mat-icon>apps</mat-icon>
  </button>
  <button mat-icon-button routerLink="/" title="Home Page" (click)="closeDevTool()">
    <mat-icon>home</mat-icon>
  </button>
</mat-nav-list>
