<router-outlet />
@if (!isProductionMode) {
  <button
    mat-icon-button
    class="floating-button"
    (click)="openBottomSheet()"
    title="Development Tool"
  >
    <mat-icon>settings</mat-icon>
  </button>
}
