import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { RichTooltipDirective } from '@shared/directives';

@Component({
  selector: 'app-cp-tooltip',
  imports: [MatButton, RichTooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!--Custom tooltip template-->
    <ng-template #tooltipTemplate>
      <div class="grid grid-flow-row gap-2">
        <div>
          <strong>Advanced Tooltip</strong>
          <p>This is a tooltip with custom HTML content.</p>
        </div>
        <div>
          <strong>Advanced Tooltip</strong>
          <p>This is a tooltip with custom HTML content.</p>
        </div>
        <div>
          <strong>Advanced Tooltip</strong>
          <p>This is a tooltip with custom HTML content.</p>
        </div>
      </div>
    </ng-template>

    <label>Tooltips with Template:</label>
    <div class="grid grid-flow-col mt-60">
      <div class="mr-4">
        <button
          mat-stroked-button
          color="primary"
          [appRichTooltip]="tooltipTemplate"
          position="top"
        >
          Top
        </button>
      </div>

      <div class="mr-4">
        <button
          mat-stroked-button
          color="primary"
          [appRichTooltip]="tooltipTemplate"
          position="left"
        >
          Left
        </button>
      </div>

      <div class="mr-4">
        <button
          mat-stroked-button
          color="primary"
          [appRichTooltip]="tooltipTemplate"
          position="right"
        >
          Right
        </button>
      </div>

      <div class="mr-4">
        <button
          mat-stroked-button
          color="primary"
          [appRichTooltip]="tooltipTemplate"
          position="bottom"
        >
          Bottom
        </button>
      </div>
    </div>
  `,
  styles: ``,
})
export class CpTooltipComponent {}
