import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ROUTES } from '@core/constants';
import { ApiResponse, KYCProviderDetails } from '@core/models';
import { HttpService } from '@core/services';
import {
  CurrencyFormatterPipe,
  FileSizePipe,
  HumanizedFileSizeToBytePipe,
  LocalizedDatePipe,
  ParseAndFormatNumbersPipe,
  TrimTrailingZerosPipe,
  WithLoadingPipe,
} from '@shared/pipes';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoadingComponent, RetryComponentComponent } from '@shared/components';

@Component({
  selector: 'app-cp-pipes',
  imports: [
    CurrencyFormatterPipe,
    NgxMaskPipe,
    NgIf,
    WithLoadingPipe,
    AsyncPipe,
    JsonPipe,
    LoadingComponent,
    RetryComponentComponent,
    ParseAndFormatNumbersPipe,
    TrimTrailingZerosPipe,
    LocalizedDatePipe,
    HumanizedFileSizeToBytePipe,
    FileSizePipe,
  ],
  providers: [provideNgxMask()],
  template: `
    <h2>TrimTrailingZerosPipe</h2>

    <p>Input:'123.45000' , Output: {{ '123.45000' | trimTrailingZeros }}</p>
    <p>Input:'123.0033' , Output: {{ '123.0033' | trimTrailingZeros }}</p>
    <p>Input:'123000' , Output: {{ '123000' | trimTrailingZeros }}</p>
    <p>Input:'14000' , Output: {{ '14000' | trimTrailingZeros }}</p>
    <p>Input:'0.4002800000' , Output: {{ '0.4002800000' | trimTrailingZeros }}</p>
    <p>Input:'4002800000' , Output: {{ '4002800000' | trimTrailingZeros }}</p>

    <h2>CurrencyFormatterPipe</h2>
    <p>{{ 123456789 | currencyFormatter: 'IRR' }}</p>
    <p>{{ 1234567.89 | currencyFormatter: 'USDT' }}</p>
    <p>{{ '987654321' | currencyFormatter }}</p>
    <p>{{ 1234567.89 | mask: 'separator' : { thousandSeparator: ',', suffix: ' IRR' } }}</p>
    <h2>ParseAndFormatNumbersPipe</h2>
    <p>
      {{
        'Transaction completed: Paid 27912223.1545545 units at a rate of 0.1234332 and included an additional fee of 349934.2342333'
          | parseAndFormatNumbers: 4
      }}
    </p>
    <p>
      {{
        'Transaction completed: Paid 23 units at a rate of 0.1003423 and included an additional fee of 349934.002200'
          | parseAndFormatNumbers: 3
      }}
    </p>
    <p>
      {{
        'Transaction completed: Paid 2325454.545 units at a rate of 0.122343 and included an additional fee of 349934.233234'
          | parseAndFormatNumbers: 2
      }}
    </p>

    <p>{{ '' | parseAndFormatNumbers: 2 }}</p>
    <p>
      <ng-container *ngIf="data$ | withLoading | async as state">
        <app-loading [loading]="state.loading">
          @if (state.value) {
            <div>{{ state.value | json }}</div>
          }
          @if (state.error) {
            <app-retry-component [error]="state.error" (onRetry)="loadData()"></app-retry-component>
          }
        </app-loading>
      </ng-container>
    </p>
    <div>
      <p>Current Date (Short): {{ currentDate | localizedDate }}</p>
      <!-- Default usage -->
      <p>Current Date (Short): {{ currentDate | localizedDate }}</p>

      <!-- Custom format -->
      <p>
        Current Date (Short):
        {{
          currentDate
            | localizedDate: { format: { year: 'numeric', month: 'long', day: 'numeric' } }
        }}
      </p>

      <!-- Disable Western digits -->
      <p>Current Date (Short): {{ currentDate | localizedDate: { useWesternDigits: false } }}</p>

      <!-- Different locale -->
      <p>Current Date (Short): {{ currentDate | localizedDate: { locale: 'fa-IR-u-nu-latn' } }}</p>
      <p>Current Date (Short): {{ currentDate | localizedDate: { locale: 'en-US' } }}</p>

      <!-- Convert humanized file size to bytes -->
      <div>Bytes: {{ '256KB' | humanizedFileSize }}</div>

      <!-- Convert bytes to humanized format -->
      <div>Bytes to Humanized File size: {{ 262144 | fileSize }}</div>
      <div>
        Humanized to byptes then to Humanized to: {{ '256KB' | humanizedFileSize | fileSize }}
      </div>
    </div>
  `,
  styles: ``,
})
export class CpPipesComponent {
  readonly httpService = inject(HttpService);
  data$: Observable<KYCProviderDetails> | undefined;
  currentDate = new Date();

  constructor() {
    this.loadData();
  }

  loadData() {
    // Define the URL for the HTTP request.
    const url = ROUTES.configuration.KYCProviders.retrieveKYCDetails(1);

    // Simulate a delayed HTTP call with an error.
    this.data$ = of(null).pipe(
      switchMap(() =>
        Math.random() > 0.5 // Randomly decide whether to succeed or fail
          ? this.httpService
              .get<ApiResponse<KYCProviderDetails>>(url)
              .pipe(map((response) => response.data))
          : throwError(() => new Error('Simulated error!')),
      ),
      catchError((error) => {
        // Return an observable with a null value or handle the error state
        return throwError(() => error);
      }),
    );
  }
}
