import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-cp-tabs',
  imports: [MatTabsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="app-mat-mdc-tab-group">
      <mat-tab label="First">Content 1</mat-tab>
      <mat-tab label="Second">Content 2</mat-tab>
      <mat-tab label="Third">Content 3</mat-tab>
    </mat-tab-group>
  `,
  styles: ``,
})
export class CpTabsComponent {}
