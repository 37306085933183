import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-cp-colors',
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="color-container">
      @for (color of colors; track $index) {
        <div class="color-swatch">
          <span>{{ color.name }}</span>
          <div class="color-swatch-box" [style.backgroundColor]="color.value"></div>
        </div>
      }
    </div>
  `,
  styles: `
    .color-container {
      display: flex;
      flex-wrap: wrap;
    }

    .color-swatch .color-swatch-box {
      width: 300px;
      height: 100px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: yellow;
      font-weight: bold;
      text-align: center;
      box-sizing: border-box;
    }
  `,
})
export class CpColorsComponent {
  colors = [
    { name: '--white', value: 'var(--white)' },
    { name: '--black', value: 'var(--black)' },
    { name: '--body-background-color', value: 'var(--body-background-color)' },
    { name: '--mat-sidenav-content-bg', value: 'var(--mat-sidenav-content-bg)' },
    { name: '--c-primary-10', value: 'var(--c-primary-10)' },
    { name: '--c-primary-20', value: 'var(--c-primary-20)' },
    { name: '--c-primary-30', value: 'var(--c-primary-30)' },
    { name: '--c-primary-40', value: 'var(--c-primary-40)' },
    { name: '--c-primary-50', value: 'var(--c-primary-50)' },
    { name: '--c-primary-60', value: 'var(--c-primary-60)' },
    { name: '--c-primary-70', value: 'var(--c-primary-70)' },
    { name: '--c-primary-80', value: 'var(--c-primary-80)' },
    { name: '--c-primary-90', value: 'var(--c-primary-90)' },
    { name: '--c-primary-95', value: 'var(--c-primary-95)' },
    { name: '--c-secondary-10', value: 'var(--c-secondary-10)' },
    { name: '--c-secondary-20', value: 'var(--c-secondary-20)' },
    { name: '--c-secondary-30', value: 'var(--c-secondary-30)' },
    { name: '--c-secondary-40', value: 'var(--c-secondary-40)' },
    { name: '--c-secondary-50', value: 'var(--c-secondary-50)' },
    { name: '--c-secondary-60', value: 'var(--c-secondary-60)' },
    { name: '--c-secondary-70', value: 'var(--c-secondary-70)' },
    { name: '--c-secondary-80', value: 'var(--c-secondary-80)' },
    { name: '--c-secondary-90', value: 'var(--c-secondary-90)' },
    { name: '--c-secondary-95', value: 'var(--c-secondary-95)' },
    { name: '--c-error-10', value: 'var(--c-error-10)' },
    { name: '--c-error-20', value: 'var(--c-error-20)' },
    { name: '--c-error-30', value: 'var(--c-error-30)' },
    { name: '--c-error-40', value: 'var(--c-error-40)' },
    { name: '--c-error-50', value: 'var(--c-error-50)' },
    { name: '--c-error-60', value: 'var(--c-error-60)' },
    { name: '--c-error-70', value: 'var(--c-error-70)' },
    { name: '--c-error-80', value: 'var(--c-error-80)' },
    { name: '--c-error-90', value: 'var(--c-error-90)' },
    { name: '--c-error-95', value: 'var(--c-error-95)' },
    { name: '--c-green-base', value: 'var(--c-green-base)' },
    { name: '--c-green-light', value: 'var(--c-green-light)' },
    { name: '--c-yellow-base', value: 'var(--c-yellow-base)' },
    { name: '--c-yellow-light', value: 'var(--c-yellow-light)' },
    { name: '--c-red-base', value: 'var(--c-red-base)' },
    { name: '--c-red-light', value: 'var(--c-red-light)' },
    { name: '--c-orange-base', value: 'var(--c-orange-base)' },
    { name: '--c-orange-light', value: 'var(--c-orange-light)' },
    { name: '--c-blue-base', value: 'var(--c-blue-base)' },
    { name: '--c-blue-light', value: 'var(--c-blue-light)' },
  ];
}
