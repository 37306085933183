import {
  provideRouter,
  Routes,
  withComponentInputBinding,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { authGuard, loginGuard } from '@core/guards';
import { BreadCrumb } from '@core/models';
import { LoginComponent } from '@views';
import { NonProductionGuard } from './core/guards/non-production.guard';
const AppRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'cpl' },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard],
  },

  {
    path: 'cpl',
    loadChildren: () => import('./views/cpl/cpl.routes').then((x) => x.CPL_ROUTES),
    canActivate: [authGuard],
    data: {
      breadcrumb: {
        icon: 'fa-regular fa-house',
        iconOnly: true,
        label: 'Dashboard',
        extraIconStyleClass: 'material-icons-outlined',
      } as BreadCrumb,
    },
  },
  {
    path: 'cp',
    canMatch: [NonProductionGuard],
    loadChildren: () => import('@dev-tools').then((x) => x.COMPONENT_PLAYGROUND_ROUTES),
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

// app.route rewriting the router providers using provideRouter
export const AppRouteProviders = [
  provideRouter(
    AppRoutes,
    // this is in place of scrollPositionRestoration: 'disabled',
    withInMemoryScrolling({
      scrollPositionRestoration: 'disabled',
    }),
    withComponentInputBinding(),
    // in place of initialNavigation: 'enabledBlocking'
    // Enabling this function will cause your guards to run before app initializers.
    // Note: If your guards rely on data provided by app initializers, enabling this may lead to data loss when
    // reloading the application, as guards won't wait for initializer data.
    // This method is necessary for server-side rendering:
    // withEnabledBlockingInitialNavigation(),
    // same configuration
    withRouterConfig({
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
    // in place of  preloadingStrategy: PreloadService,
    //   withPreloading(PreloadService),
  ),
];
