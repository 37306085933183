import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { JsonPipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { AsyncActionableSelectorInterface } from '@core/interfaces';
import { HttpService } from '@core/services';
import { SafeAny } from '@core/types';
import { ActionableSelectorComponent } from '@shared/components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-cp-actionable-selector',
  imports: [ActionableSelectorComponent, ReactiveFormsModule, MatButtonModule, JsonPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    All Inputs here are required :
    <form [formGroup]="testForm" (ngSubmit)="onSubmit()" class="mb-4 mt-4">
      <div class="w-3/12">
        <app-actionable-selector
          controlName="firstSelector"
          placeholder="Actionable Selector Options"
          [optionGroups]="firstOptionGroups"
        ></app-actionable-selector>
      </div>

      <div class="w-6/12">
        <app-actionable-selector
          controlName="secondSelector"
          placeholder="Multi Group Selector"
          [optionGroups]="secondOptionGroups"
        ></app-actionable-selector>
      </div>
      <div class="w-9/12">
        <app-actionable-selector
          controlName="thirdSelector"
          placeholder="Actionable Selector Options with local search"
          [optionGroups]="thirdOptionGroups()"
          (onActionClick)="optionActionApiCall()"
          [filterable]="true"
        ></app-actionable-selector>
      </div>
      <button mat-stroked-button color="primary" type="submit">Submit Form</button>
    </form>
    @if (finalForm) {
      {{ finalForm | json }}
    }
  `,
  styles: ``,
})
export class CpActionableSelectorComponent implements OnInit {
  readonly httpService = inject(HttpService);
  testForm: FormGroup = new FormGroup({});
  finalForm: SafeAny;
  addWalletLoadingState = false;

  firstOptionGroups: AsyncActionableSelectorInterface[] = [
    {
      options: [
        { value: 'USDT 2348 Value', viewValue: 'USDT 2348' },
        { value: 'Dirham 5789 Value', viewValue: 'Dirham 5789' },
      ],
    },
    {
      name: 'Add Wallet',
      hasAction: true,
      options: [
        { value: 'USDT Value', viewValue: 'USDT' },
        { value: 'Euro Value', viewValue: 'Euro' },
        { value: 'Lira Value', viewValue: 'Lira' },
      ],
    },
  ];

  secondOptionGroups: AsyncActionableSelectorInterface[] = [
    {
      options: [
        { value: 'bulbasaur-0', viewValue: 'Bulbasaur', disabled: true },
        { value: 'oddish-1', viewValue: 'Oddish' },
        { value: 'bellsprout-2', viewValue: 'Bellsprout' },
      ],
    },
    {
      name: 'Water',
      options: [
        { value: 'squirtle-3', viewValue: 'Squirtle' },
        { value: 'psyduck-4', viewValue: 'Psyduck' },
        { value: 'horsea-5', viewValue: 'Horsea' },
      ],
    },
    {
      name: 'Fire',
      disabled: true,
      options: [
        { value: 'charmander-6', viewValue: 'Charmander' },
        { value: 'vulpix-7', viewValue: 'Vulpix', disabled: true },
        { value: 'flareon-8', viewValue: 'Flareon' },
      ],
    },
    {
      name: 'Psychic',
      options: [
        { value: 'mew-9', viewValue: 'Mew' },
        { value: 'mewtwo-10', viewValue: 'Mewtwo' },
      ],
    },
  ];

  thirdOptionGroups = signal<AsyncActionableSelectorInterface[] | null>(null);

  ngOnInit() {
    this.initForm();
    this.initLazyList();
  }

  initForm() {
    this.testForm = new FormGroup({
      firstSelector: new FormControl('', [Validators.required]),
      secondSelector: new FormControl('', [Validators.required]),
      thirdSelector: new FormControl('', [Validators.required]),
    });
  }

  initLazyList() {
    // An Api to get one group of option data
    this.lazyList$().subscribe((data) => {
      const listWithoutAction = {
        options: data,
      };
      const firstOptionsList = [listWithoutAction];
      this.staticList(firstOptionsList);
    });
  }

  staticList(firstOptionsList: SafeAny[]) {
    // Another Api to get another group of option data
    this.httpService
      .get('/Currencies/summary')
      .pipe(
        map((item: SafeAny) =>
          item.data.items.map((subItem: SafeAny) => ({
            value: subItem.id,
            viewValue: subItem.code,
            icon: subItem.logoUrl,
          })),
        ),
      )
      .subscribe((data: SafeAny) => {
        const listWithAction = {
          name: 'Add Wallet',
          hasAction: true,
          options: data,
        };
        const finalList = [...firstOptionsList, listWithAction];
        this.thirdOptionGroups.set(finalList);
      });
  }

  lazyList$(): Observable<SafeAny> {
    return this.httpService.get('/Partners/1/wallets').pipe(
      map((item: SafeAny) =>
        item.data.items.map((subItem: SafeAny) => ({
          value: subItem.id,
          viewValue: subItem.name,
          icon: subItem.logoUrl,
        })),
      ),
    );
  }

  optionActionApiCall() {
    this.addWalletLoadingState = true;
    this.httpService.post('/partners/1/wallets', {}).subscribe(() => {
      this.lazyList$().subscribe((data) => {
        const baseData = {
          options: data,
        };
        const updatedOptions = [...this.thirdOptionGroups()!, baseData];
        this.thirdOptionGroups.set(updatedOptions);
      });
      this.addWalletLoadingState = false;
    });
  }

  onSubmit() {
    this.finalForm = this.testForm.getRawValue();
  }
}
